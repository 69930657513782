import { render, staticRenderFns } from "./BurgerMenu.vue?vue&type=template&id=7116a7fc&"
import script from "./BurgerMenu.vue?vue&type=script&lang=js&"
export * from "./BurgerMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButtonIcon: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Ui/Button/Icon.vue').default,BrandingLogo: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Branding/Logo.vue').default,UiButton: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Ui/Button/index.vue').default,UiAvatar: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Ui/Avatar.vue').default,MenuBurger: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Menu/Burger.vue').default,Menu: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Menu/index.vue').default,UiLanguageSwitch: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Ui/LanguageSwitch.vue').default})
