import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=7a2a9019&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButton: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Ui/Button/index.vue').default,Section: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Section/Index.vue').default,BrandingIcon: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Branding/Icon.vue').default,Section: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Section/Index.vue').default,FormCookieSettings: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Form/CookieSettings.vue').default,Menu: require('/codebuild/output/src3002460273/src/med-el-hearpeers/components/Menu/index.vue').default})
